import React from 'react';
import { RichText } from 'prismic-reactjs'
import { getTips } from '../api/queries';
import { useEffect, useState } from 'react';
import ScrollIn from '../Components/ScrollIn';

import '../styles/tips.scss'

function Tips() {

    const [posts, updatePosts] = useState(false)

    useEffect(() => {

        getData();

    }, [])

    function getData() {
        const data = getTips().then(datan => {
            updatePosts(datan)
            //console.log(datan)
        })
        //console.log(data)

    }

    const tips = posts.tips;
    //console.log('tips data', tips);

    return (

        <div id="tips" className="yellowBG">
            <div className="container">
                <div className="inner columns block">
                    <header className="columns__header">
                        <h2 className=""> Bra att veta</h2>
                    </header>
                    {(posts && tips) &&

                        Object.keys(tips).map((i) => {
                            let item = tips[i];
                            const title = RichText.asText(item.list_title);
                            const text = RichText.render(item.list_text);
                            return (
                                <ScrollIn offset={`${i}00`} key={`tips-${i}`}>
                                    <div className="columns__col--2 align-left">
                                        <h3>{title}</h3>
                                        <p>{text}</p>
                                    </div>
                                </ScrollIn>
                            )
                        })
                    }
                </div>
            </div>
        </div>

    );
}

export default Tips;
