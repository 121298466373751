import React from 'react';
import { useEffect, useState } from 'react';
import { getImages } from '../api/queries';
import { RichText } from 'prismic-reactjs'
import ScrollIn from '../Components/ScrollIn';

import '../styles/columns-images.scss';

/*
const Images = () => {

    const [posts, updatePosts] = useState(false)

    useEffect(() => {

        getData();

    }, [])

    function getData() {
        const data = getImages().then(datan => {
            updatePosts(datan)
            //console.log(datan)
        })
        //console.log(data)
    }

    const images = posts.images;
    const photo = posts.image_text;

    //const images = posts[0].posts.body[0].images;
    //const photo = RichText.asText(posts[0].posts.image_text);
    
    //console.log(images +"image array")


  let highlights = [];

  images.map((image) => {

    if(image.show_on_startpage){

      highlights.push(image);
    }
    return true;
  });

  // const content = data[0].data;

// const items = ["1","2","3","4","5","6"];

  return (
    <div className="block bg--blue scroll-point">

      <div className="container">
        <div className="images">
          { (highlights[0] || highlights[1]) &&
            <div className="flex-row scroll-point scroll-point--inner">
            { highlights[0] &&
              <figure>

                  <img className="img-in" src={highlights[0].gallery_image.url} alt=""/>

              </figure>
            }
            { highlights[1] &&
              <figure>

                  <img className="img-in" src={highlights[1].gallery_image.url} alt=""/>


              </figure>
            }
            </div>
          }
          { highlights[2] &&
            <div className="flex-row scroll-point scroll-point--inner">
              <figure>
                <img className="img-in" src={highlights[2].gallery_image.url} alt=""/>
              </figure>
            </div>
          }

          { (highlights[3] || highlights[4]) &&
            <div className="flex-row scroll-point scroll-point--inner">
            { highlights[3] &&
              <figure>

                <img className="img-in" src={highlights[3].gallery_image.url} alt=""/>

              </figure>
            }
            { highlights[4] &&
              <figure>

                <img className="img-in" src={highlights[4].gallery_image.url} alt=""/>

              </figure>
            }
            </div>
          }
          <span className="photographer">Foto: {photo}</span>

        </div>
      </div>

    </div>
  )
}

export default Images;*/


function Images() {

    const [posts, updatePosts] = useState(false)

    useEffect(() => {

        getData();

    }, [])

    function getData() {
        const data = getImages().then(datan => {
            updatePosts(datan)
            //console.log(datan)
        })
        //console.log(data)
    }

    const images = posts.images;
    //console.log('images data', images);

    return (

        <div className="block scroll-point imageBlock">
            <div className="container">
                <div className="images">
                    {(posts && images) &&
                        Object.keys(images).map((i) => {
                            let item = images[i];
                            const image = (item.image);
                            //const title = RichText.asText(item.image_title);
                            const text = RichText.asText(item.image_text);
                            return (
                            <ScrollIn offset={`${i}00`} key={`tips-${i}`}>
                                <figure className="flex-row" key={"image-" + i}>
                                    <img alt="Bilder från resan" className="image img" src={image.url} />
                                    <p className="img-text">{text}</p>
                                </figure>
                            </ScrollIn>)
                        })
                    }
                </div>
            </div>

        </div>

    )
}

export default Images;
