import React, {Component} from 'react';
// import logo from '../img/footer_logo.svg';
import logo from '../img/vit-transparant.png';

import '../styles/footer.scss';

Math.easeInOutQuad = function (t, b, c, d) {
  t /= d/2;
  if (t < 1) return c/2*t*t + b;
  t--;
  return -c/2 * (t*(t-2) - 1) + b;
};

class Footer extends Component {

 
  constructor(props){
    super(props)
    this.handleToggleClick = this.handleToggleClick.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
 
    this.state = {
      menuOpen:false
    }
 
    this.navItems = this.props.links;
  }
 
  handleToggleClick(){
    this.setState({
      menuOpen: !this.state.menuOpen
    })
  }
 
  scrollToId(id, duration, changeHash) {
     changeHash=changeHash || false;
     var root = document.scrollingElement || document.documentElement;
     const scrollTo = document.getElementById(id).offsetTop - 100;
 
     var start = root.scrollTop,
         change = scrollTo - start,
         currentTime = 0,
         increment = 20;
 
     var animateScroll = function(){
         currentTime += increment;
         var val = Math.easeInOutQuad(currentTime, start, change, duration);
         root.scrollTop = val;
         if(currentTime < duration) {
             setTimeout(animateScroll, increment);
         }
         else {
           if(changeHash){
             window.location.hash = id;
           }
         }
     };
     animateScroll();
   }
 
 
 
  handleLinkClick(e){
    //console.log('klick, go to');
    e.preventDefault();
 
    let target = e.currentTarget;
    let id = target.getAttribute('href').split('#')[1];
    //console.log('klick', id);
    this.scrollToId(id, 500, false)
 
    if(!target.classList.contains('logo')){
      this.handleToggleClick();
    }
  }

  render() {
    /*<footer className="footer">
        <div className="flex-col">
            <h4>Res dig</h4>
        </div>
        <div className="flex-col">
            hej
        </div>
        <div className="flex-col">
            <h4>Kontakt</h4>
            <a href=''>Epost</a>< br/>
            <a href=''>Instagram</a> <br/>
            <a href=''>Facebook</a>
        </div>
    </footer>*/
    return (
    <footer className="blueBG">
      <div className="page-footer block bg--yellow">
        <div className="container inner">
        <h3>Res dig tillsammans med oss.</h3>
          <div className="flex-row flex--spread">
            <div className="flex-row flex--start">
              <nav className="flex-col">
                <p className="footer-title"><b>Kontakt</b></p>
                <a href="mailto:susanne@susannedalsatt.se" target="_blank" rel="noopener noreferrer">Epost</a>
                <a href="https://www.instagram.com/resdig_camp" target="_blank" rel="noopener noreferrer">Instagram</a>
                <a href="https://www.facebook.com/resdig_camp-112694867981899" target="_blank" rel="noopener noreferrer">Facebook</a>
              </nav>
              <nav className="flex-col">
                <br></br>
                {
                  this.navItems.map((item, i) => {
                    return (
                      <a className="nav-links" key={"menu-link-" + i} onClick={this.handleLinkClick} href={"#" + item.id}>{item.text}</a>
                    )
                  })
                }                
              </nav>
            </div>
            <div className="logo-wrapper">
              <img alt="logo" className="logo" src={logo}/>
            </div>
          </div>
        </div>
      </div>
    </footer>
    )
  }
};

export default Footer;
