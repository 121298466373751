import { client } from "../utils/config";
import Prismic from "prismic-javascript";

export async function getHero(type='hero'){
    const res = await client.query (
        Prismic.Predicates.at('document.type', type),
    );
    const allPostData = res.results.map((p) => {
        return p.data
    }); 
    return allPostData[0] 
}

export async function getAbout(type='about'){
    const res = await client.query (
        Prismic.Predicates.at('document.type', type),
    );
    const allPostData = res.results.map((p) => {
        return p.data
    }); 
    return allPostData[0] 
}

export async function getTips(type='tips'){
    const res = await client.query (
        Prismic.Predicates.at('document.type', type),
    );
    const allPostData = res.results.map((p) => {
        return p.data
    }); 
    return allPostData[0] 

}

export async function getLedare(type='ledare'){
    const res = await client.query (
        Prismic.Predicates.at('document.type', type),
    );
    const allPostData = res.results.map((p) => {
        return p.data
    }); 
    return allPostData[0] 

}

export async function getImages(type='images'){
    const res = await client.query (
        Prismic.Predicates.at('document.type', type),
    );
    const allPostData = res.results.map((p) => {
        return p.data
    }); 
    return allPostData[0] 

}