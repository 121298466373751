import React from 'react';
import { RichText } from 'prismic-reactjs'
import { getLedare } from '../api/queries';
import { useEffect, useState } from 'react';

import '../styles/ledare.scss'
import '../styles/images.scss'


function Ledare() {

    const [posts, updatePosts] = useState(false)

    useEffect(() => {

        getData();

    }, [])

    function getData() {
        const data = getLedare().then(datan => {
            updatePosts(datan)
            //console.log(datan)
        })
        //console.log(data)

    }

    //const ledare = posts.ledare;
    const annlouise = posts.annlouise;
    const kristoffer = posts.kristoffer;
    const susanne = posts.susanne;

    //const annlouisetitle = RichText.asText(annlouise.list_title);
    // const annlouisetext = RichText.render(annlouise.list_text);

    const image1 = posts.image1;
    const image2 = posts.image2;
    const image3 = posts.image3;

    ////console.log('ledare data', ledare);
    //console.log('ann louise data', annlouise)
    //console.log('kristoffer data', kristoffer)
    //console.log('kristoffer data', susanne)


    return (

        <div id="ledare">
            {posts ?
                <>
                    <h2>Om oss</h2>

                    <div className="profileImgCont">
                        <img className="ledarImage yellowBorder" alt="Porträttbild på resledaren Ann-Louise" src={image1.url} />
                        <img className="ledarImage blueBorder" alt="Porträttbild på resledaren Kristoffer" src={image2.url} />
                        <img className="ledarImage pinkBorder" alt="Porträttbild på resledaren Susanne" src={image3.url} />
                    </div>
                    <div className="ledare">
                        <div className="annlouise">
                            <h3 className="yellow"> {RichText.asText(annlouise[0].list_title)} </h3>
                            <p className="text">{RichText.render(annlouise[0].list_text)}</p>
                        </div>
                        <div className="kristoffer">
                            <h3 className="blue"> {RichText.asText(kristoffer[0].list_title)} </h3>
                            <p className="text">{RichText.render(kristoffer[0].list_text)}</p>
                        </div>
                        <div className="susanne">
                            <h3 className="pink"> {RichText.asText(susanne[0].list_title)} </h3>
                            <p className="text">{RichText.render(susanne[0].list_text)}</p>
                        </div>
                    </div>
                    {
                        /*Object.keys(ledare).map((i) => {
                            let item = ledare[i];
                            const title = RichText.asText(item.list_title);
                            const text = RichText.render(item.list_text);*/
                        /* return (
                             /*<div className="ledare" key={"ledare-" + i}>
                                 <h3>{title}</h3>
                                 <p className="text">{text}</p>
                             </div>

                             <div>

                             </div>  
                         )
                     })*/
                    }
                </>
                : <>laddar</>}
        </div>
    );
}

export default Ledare;
