import React from 'react';
import { RichText } from 'prismic-reactjs'
import { getAbout } from '../api/queries';
import { useEffect, useState } from 'react';
import ScrollIn from '../Components/ScrollIn';
import '../styles/global.scss'

function About() {

    const [posts, updatePosts] = useState(false)

    useEffect(() => {

        getData();

    }, [])

    function getData() {
        const data = getAbout().then(datan => {
            updatePosts(datan)
            //console.log(datan)
        })
        //console.log(data)

    }

    const about = posts.about;
    //const link = RichText.asText(about.booking)
    //console.log('link data', link);

    console.log('post', posts);
    const linkUrl = (posts && posts.booking) ? posts.booking.url : '';

    return (
        <div id="about" className="aboutCont">
            <div className="container">
                <div className="inner columns block">
                    <header className="columns__header">
                        <h2>{RichText.asText(posts.title)}</h2>
                        {RichText.render(posts.text)}
                    </header>
                {  
                    (posts && about) &&
                        Object.keys(about).map((i) => {
                            let item = about[i];
                            const title = RichText.asText(item.list_title);
                            const text = RichText.render(item.list_text);
                            return (
                                <ScrollIn offset={`${i}00`} key={`tips-${i}`}>
                                    <div key={"about-" + i} className="columns__col--2 align-left">
                                        <h3>{title}</h3>
                                        <p className="text">{text}</p>
                                    </div>
                                </ScrollIn>
                            )
                        })
                    }
                </div>
                <div className="btnCont">
                    <a className="btn" target="_blank" href={linkUrl}> 
                        Boka här!
                    </a>

                    {/* <button className="btn"
                        type="button"
                        onClick={(e) => {
                        e.preventDefault();
                        // window.open("https://customervoice.microsoft.com/Pages/ResponsePage.aspx?id=nmEN8VRh5EWYeSg2QJINyoSBP5-v3X5CtR4mFNeFAMRUQklSTFpCUlU1Qk9SNkNXMTg1RTlXTTVMTy4u")
                        window.open(linkUrl)
                        }}> 
                        <h3>Boka här! {linkUrl}</h3>
                    </button> */}
                    <br></br>
                    <p className="btn-text"><i>Öppnar extern länk.</i></p>
                </div>
            </div>
        </div>
    );
}

export default About;
