import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import React, { createRef, useState } from 'react';

const Scrolln = ({ children, offset = 0 }) => {

    const elementRef = createRef();
    const [scrollStyle, setScrollStyle] = useState({
        transition: 'all 0.1s linear',
        display: 'inherit',
        width: 'inherit'
    })

    useScrollPosition(

        ({ currPos }) => {
            if (!elementRef) return;
            let rect = elementRef.current !== null ? elementRef.current.getBoundingClientRect() : {};
            const inView = rect.top <= window.innerHeight;
            const scrollOffset = window.innerHeight + parseInt(offset);
            const scrollDist = { x: 0, y: 60 };
            //const inTop = rect.top <= 0;
            const inBottom = rect.bottom <= 0;
            //const diff = ((rect.top) * 1);
            // const scrollY = inTop ? 200 - diff : 0;
            // const opCalc = inView ? (rect.top / -1000) : 0;
            const opCalc = inView ? ((rect.top - scrollOffset) / -2000) : 0;
            let opacity = 0;
            let scrollY = 100;
            switch (true) {
                case (opCalc <= 0):
                    scrollY = scrollDist.y;
                    opacity = 0;
                    break;
                case (opCalc >= 1):
                    scrollY = 0;
                    opacity = 1;
                    break;
                default:
                    scrollY = scrollDist.y - ((rect.top - scrollOffset) / -10);
                    opacity = 0 + opCalc * 3
                    break;
            }
            const shouldBeStyle = {
                transform: scrollY > 0 ? `translate3d(0, ${scrollY}px, 0)` : 'none',
                opacity: !inView ? '1' : `${opacity}`,
                pointerEvents: inBottom ? 'none' : 'all',
                transition: 'all .01s linear',
                willChange: ' transform, opacity',
                display: 'inherit',
                width: 'inherit'
            }
            if (JSON.stringify(shouldBeStyle) === JSON.stringify(scrollStyle)) return
            setScrollStyle(shouldBeStyle)
        }, [scrollStyle]
    )

    return (

        <div ref={elementRef} style={{
            display: 'inherit',
            width: 'inherit'
        }}>
            <div style={{ ...scrollStyle }}>
                {children}
            </div>
        </div>
    );
};

export default Scrolln;