import React, {Component} from 'react';
// import SvgLogo from '../img/vit-transparant.png'; //logga svg component

import '../styles/nav.scss';

Math.easeInOutQuad = function (t, b, c, d) {
  t /= d/2;
  if (t < 1) return c/2*t*t + b;
  t--;
  return -c/2 * (t*(t-2) - 1) + b;
};

class Nav extends Component {
  
 constructor(props){
   super(props)
   this.handleToggleClick = this.handleToggleClick.bind(this);
   this.handleLinkClick = this.handleLinkClick.bind(this);

   this.state = {
     menuOpen:false
   }

   this.navItems = this.props.links;
 }

 handleToggleClick(){
   this.setState({
     menuOpen: !this.state.menuOpen
   })
 }

 scrollToId(id, duration, changeHash) {
    changeHash=changeHash || false;
    var root = document.scrollingElement || document.documentElement;
    const scrollTo = document.getElementById(id).offsetTop - 100;

    var start = root.scrollTop,
        change = scrollTo - start,
        currentTime = 0,
        increment = 20;

    var animateScroll = function(){
        currentTime += increment;
        var val = Math.easeInOutQuad(currentTime, start, change, duration);
        root.scrollTop = val;
        if(currentTime < duration) {
            setTimeout(animateScroll, increment);
        }
        else {
          if(changeHash){
            window.location.hash = id;
          }
        }
    };
    animateScroll();
  }



 handleLinkClick(e){
   //console.log('klick, go to');
   e.preventDefault();

   let target = e.currentTarget;
   let id = target.getAttribute('href').split('#')[1];
   //console.log('klick', id);
   this.scrollToId(id, 500, false)

   if(!target.classList.contains('logo')){
     this.handleToggleClick();
   }
 }

 render(){

    return (
      <div className={ this.state.menuOpen ? "menu-open top-header": "top-header"}>
        <div className="menu-toggle" onClick={this.handleToggleClick}></div>
        <nav className="menu">

          {
            this.navItems.map((item,i)=>{
            return (
              <a className ="nav-links" key={"menu-link-"+i} onClick={this.handleLinkClick} href={"#" + item.id}>{ item.text }</a>
            )
            })
          }
        </nav>
      </div>
    )
  }
}

export default Nav;
