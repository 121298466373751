import React from 'react';
import { useEffect, useState } from 'react';
import videobg from '../videos/waves.mp4';
import logo from '../img/vit-transparant.png';
//import { getHero } from '../api/queries';
import WindowProps from '../functions/WindowProps';
import { RichText } from 'prismic-reactjs';
import '../styles/hero.scss'

Math.easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
}; //extern

const Header = ({ links }) => {

   /* const [posts, updatePosts] = useState(false)

    useEffect(() => {

        getData();

    }, [])

    function getData() {
        const data = getHero().then(datan => {
            updatePosts(datan)
            //console.log(datan)
        })
        //console.log(data)

    }*/

    //const content = posts[0] ? posts[0].data : false;

    //var title = content.title ? RichText.asText(content.title) : false;
    //var title = RichText.asText(content.title)

    //console.log("hero titel" + title)
    //var subtitle = content.ingress ? RichText.asText(content.ingress) : false;
    //var date = subtitle;
    var videoBg = videobg


    var img = document.querySelectorAll('.bg-img')[0];
    setTimeout(function () {
        img = document.querySelectorAll('.bg-img')[0];

    }, 1000);

    window.addEventListener('scroll', (e) => {
        // //console.log(WindowProps.SCROLLTOP, img);
        if (!img) return false;
        // img.style.cssText = "transform:translateY("+WindowProps.SCROLLTOP/3+"px); filter:blur("+ WindowProps.SCROLLTOP/100 +"px)"
        img.style.cssText = "transform:translateY(" + WindowProps.SCROLLTOP / 3 + "px);"
    });
    function scrollToId(id, duration, changeHash) {
        changeHash = changeHash || false;
        var root = document.scrollingElement || document.documentElement;
        const scrollTo = document.getElementById(id).offsetTop - 100;

        var start = root.scrollTop,
            change = scrollTo - start,
            currentTime = 0,
            increment = 20;

        var animateScroll = function () {
            currentTime += increment;
            var val = Math.easeInOutQuad(currentTime, start, change, duration);
            root.scrollTop = val;
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
            else {
                if (changeHash) {
                    window.location.hash = id;
                }
            }
        };
        animateScroll();
    }



    function handleLinkClick(e) {
        //console.log('klick, go to');
        e.preventDefault();

        let target = e.currentTarget;
        let id = target.getAttribute('href').split('#')[1];
        //console.log('klick', id);
        scrollToId(id, 500, false)

    }


    return (
        /*<header id="top" className="hero abs-bg">

            <nav className="hero-menu">

                {
                    links.map((item, i) => {
                        return (
                            <a key={"menu-link-" + i} onClick={handleLinkClick} href={"#" + item.id}>{item.text}</a>
                        )
                    })
                }
            </nav>
            <div className="logo-wrapper">
                <img className="logo" alt="logo" src={logo}/>
            </div>

            <div className={"hero-image"}>
                <video muted controls={false} playsInline autoPlay loop src={videobg} class="bg-img background" />

            </div>
            <div>
                Playtias 2022 - Oktober 
            </div>
        </header>*/
        <header id="top" className="hero abs-bg">

            <nav className="hero-menu">

                {
                    links.map((item, i) => {
                        return (
                            <a key={"menu-link-" + i} onClick={handleLinkClick} href={"#" + item.id}>{item.text}</a>
                        )
                    })
                }
            </nav>

            <div className={"hero-image"}>
                <video muted controls={false} playsInline autoPlay loop src={videoBg} className="bg-img" alt="Film av vågor på tropisk strand"/>

            </div>
             
            <div className="text-content">
                <div className="logo-wrapper">
                    <img className="logo" alt="logo" src={logo} />
                </div>
                {/* <div className="title--hero change-text">
                    {title}
                </div> */}

            </div>




        </header>
    )
}

export default Header;
