import './App.scss';
import React from 'react';
import { Helmet } from "react-helmet";

//import { RichText } from 'prismic-reactjs';
//import { getHero } from './api/queries';
//import { useEffect, useState } from 'react';
import './styles/global.scss'


import Nav from './Components/Nav';
import Hero from './Blocks/Hero'
import About from './Blocks/About';
import Images from './Blocks/Images';
import Ledare from './Blocks/Ledare';
import Tips from './Blocks/Tips';
import Footer from './Blocks/Footer';
import favicon from './img/favicon.png'
//import motLog from './img/resdig-rörliglogga-loop-transparent.mp4'

function App() {

  //  const [posts, updatePosts] = useState(false)

  const navItems = [
    {
      "text": "Om Resan",
      "id": "about"
    },
    {
      "text": "Om Oss",
      "id": "ledare"
    },
    {
      "text": "Bra att veta",
      "id": "tips"
    }
  ]


  return (
    <div>
      <Helmet>
        <title>Res dig!</title>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" data-react-helmet="true"/>
      </Helmet>
      <Nav links={navItems} />
      <Hero links={navItems} />
      <About />
      <Images />
      <Ledare />
      <Tips />
      <Footer links={navItems} />
    </div>
  );
}
export default App;
